<template>
  <div>
    <h4 class="text-center">Hong Kong MACNN International Group Limited.</h4>
  </div>
</template>

<script>
import { orderShipHistoryConfirm } from "@/api/index.js";
export default {
  name: "OrderModify",

  mounted() {
    // 获取 URL 中的参数
    const hashParams = window.location.hash.split("?")[1];
    if (!hashParams) {
      console.error("缺少参数，请检查 URL");
      return; // 终止函数执行
    }

    const params = new URLSearchParams(hashParams);
    const token = params.get("token");
    const orderId = params.get("orderId");
    const shipRecordId = params.get("shipRecordId");
    const target = params.get("target");

    // 检查是否缺少任何一个参数，如果是，则重定向到根域名
    if (!token || !orderId || !shipRecordId) {
      this.$message.error("缺少参数，请检查 URL");
      window.location.href = window.location.origin; // 重定向到根域名
      return; // 终止函数执行
    }

    // 如果参数齐全，则获取订单历史记录详情
    this.getDetail(token, orderId, shipRecordId, target);
  },

  methods: {
    getDetail(token, orderId, shipRecordId, target) {
      const params = {
        token: token,
        orderId: orderId,
        shipRecordId: shipRecordId,
        target: target,
      };

      // 调用 API 获取数据
      orderShipHistoryConfirm(params)
        .then((res) => {
          // 判断后端返回的 code 值
          if (res.data.code === 1) {
            // 请求成功，重定向到根域名，并传递成功消息
            this.$message.success(this.$t("Submitted Successfully"));
            window.location.href = window.location.origin;
          } else {
            this.$message.error(this.$t("Submission Failed"));
            // 请求失败，重定向到根域名，并传递失败消息
            window.location.href = window.location.origin;
          }
        })
        .catch((error) => {
          this.$message.error(this.$t("Submission Failed"));
          // 捕获到异常，重定向到根域名，并传递异常消息
          window.location.href = window.location.origin;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
</style>
